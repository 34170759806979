import styled from '@emotion/styled'
import { Spinner } from 'app/components/Common/Spinner'
import React, { memo } from 'react'

export interface Props {
  title?: string
  subtitle?: string
}

export const BlockBooking = memo(function BlockBooking({
  title,
  subtitle,
}: Props) {
  return (
    <Container>
      <Wrapper>
        <Top>
          {title ? <Heading>{title}</Heading> : null}
          {subtitle ? <Title>{subtitle}</Title> : null}
        </Top>
        <Booking>
          <Spinner variant="invert" />
          <ConvertoForm id="converto" />
        </Booking>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  width: 100%;
  margin: 10vw 0 6vw 0;
  padding: 0 10vw;

  @media (max-width: 991px) {
    margin: 60px 0;
    margin-top: 120px;
    padding: 0 30px;
  }
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 0 6.5vw;

  @media (max-width: 991px) {
    padding: 0;
  }
`

const Booking = styled.div`
  position: relative;
  width: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight1};
  padding: 3vw 6vw;
  margin-top: 3vw;

  @media (max-width: 991px) {
    padding: 30px;
    margin-top: 30px;
  }
`

const Top = styled.div`
  max-width: 41.875rem;
  margin: 0 auto;

  @media (max-width: 1199px) {
    max-width: 21rem;
  }
`

const Heading = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5vw;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 991px) {
    font-size: 28px;
  }
`

const Title = styled.h2`
  margin-top: 1.125rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 19px;
  text-align: center;

  @media (max-width: 991px) {
    margin-top: 20px;
  }
`

const ConvertoForm = styled.div`
  max-width: 49.25rem;
  margin: 3.75rem auto 0;
  position: relative;
  z-index: 2;
  background: ${({ theme }) => theme.colors.variants.neutralLight1};

  @media (max-width: 1199px) {
    max-width: none;
    margin-right: -1.25rem;
    margin-left: -1.25rem;
  }

  @media (max-width: 991px) {
    margin-top: 0;
  }
`
